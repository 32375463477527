.b1popd8j{margin:60px 18px 60px 18px;color:#f5f5f5;}

.h1kp3hq{margin:64px 0 16px;font-size:30px;font-family:'Lobster',sans-serif;line-height:1.2;text-align:center;color:#f7df1e;}
.s1b64x3g:not(:last-child){margin-bottom:45px;}
.s1vzgibw{margin:64px 0 16px;font-size:24px;font-family:'Lobster',sans-serif;line-height:1.3;color:var(--s1vzgibw-0);}
.srhkg3i{margin:16px 0 12px;font-size:18px;font-family:'Lobster',sans-serif;line-height:1.4;color:#f7df1e;}
.p1flr7u6{font-size:18px;line-height:1.8;}.p1flr7u6:not(:last-child){margin-bottom:30px;}
.cvy03zj{margin:30px 12px;padding:30px 30px;border-radius:8px;box-shadow:18px 18px 2px 0px rgba(0,0,0,0.25);background:#233738;}@media screen and (min-width:1050px){.cvy03zj{margin:30px -45px;padding:30px 60px;}}
.cqj7v53{font-family:monospace;font-size:16px;line-height:1.5;overflow:auto;}.cqj7v53::-webkit-scrollbar{width:6px;height:6px;}.cqj7v53::-webkit-scrollbar-button:single-button{display:none;}.cqj7v53::-webkit-scrollbar-thumb{background:#ef4d8a;border-radius:12px;}
.s7k3z5z{margin-top:24px;}.s7k3z5z p{margin-top:12px;}
.b118t3nt{margin-top:30px;}
.bdv1b8p{display:inline-block;padding:12px 21px;background-color:#ef4d8a;border-radius:100px;color:#ffffff;-webkit-text-decoration:none;text-decoration:none;-webkit-transition:background-color 0.3s ease-in-out;transition:background-color 0.3s ease-in-out;}.bdv1b8p:hover{background-color:#f271a1;}
.l1pw9hx0{margin-left:60px;}.l1pw9hx0:not(:last-child){margin-bottom:30px;}
.lklijjg:not(:last-child){margin-bottom:12px;}
.c1ss1ybe{display:grid;place-items:center;}
